
.announcement-wrapper,
.calendar-wrapper, 
.messages-wrapper, 
.coming-up-wrapper, 
.upcoming-courses-wrapper,
.course-information-wrapper,
.to-do-wrapper,
.today-wrapper {
	border-radius: 15px;
	h3 {
		margin-top: $global-margin;
		margin-bottom: $global-margin;
	}
	.content-box {
		border-radius: 15px;
		background-color: $white;
		margin-bottom: $global-margin;
		margin-top: $global-margin;
		padding-right: $global-padding;
		padding-left: $global-padding;
		&.empty {
			color: $medium-gray
		}
		&.shrink-top {
			margin-top: 0;
		}
	}
}	
.announcement-wrapper,
.calendar-wrapper,
.messages-wrapper, 
.upcoming-courses-wrapper,
.course-information-wrapper,
.to-do-wrapper,
.today-wrapper {
	background-color: $pale-green;
	h3 {
		color: $black;
	}
	.remove-bottom-pad {
		padding-bottom: 0!important;
	}
}
.coming-up-wrapper,
.today-wrapper {
	background-color: get-color(primary);
	h3 {
		color: $white;
	}
	ul {
		list-style: none;
		margin-left: 0;
		li {
			border-radius: 15px;
			background-color: $medium-gray;
			margin-top: $global-margin;
			margin-bottom: $global-margin;
			&:hover {
				background-color: $pale-green;
			}
			a {
				color: $black;
				padding: $global-padding;
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.announcement-wrapper {
	ul {
		list-style: none;
		margin-left: 0;
		li {
			border-radius: 15px;
			background-color: $medium-gray;
			margin-top: $global-margin;
			margin-bottom: $global-margin;
			padding: $global-padding;
			&.information{
				border: 1px solid get-color(warning);
				background-color: scale-color( get-color(warning), $lightness: 75% );
			}
			&.urgent {
				border: 1px solid get-color(alert);
				background-color: scale-color( get-color(alert), $lightness: 75% );
			}
		}
	}
}

.messages-wrapper {
	ul {
		list-style: none;
		margin-left: 0;
		li {
			border-radius: 15px;
			background-color: $medium-gray;
			padding: $global-padding;
			margin-top: $global-margin;
			margin-bottom: 2em;
			margin-right: $global-margin;
			position: relative;
			&:after {
				content: '\f0d7';
				font-family: 'Font Awesome 6 Pro';
				font-size: 2rem;
				position: absolute;
				bottom: -30px;
				left: 15px;
				color: $medium-gray;
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
				font-weight: 900;
			}
			&.trainer {
				margin-right: 0;
				margin-left: $global-margin;
				background-color: get-color(primary);
				color: $white;
				&:after {
					bottom: -30px;
					right: 15px;
					left: unset;
					color: get-color(primary);
					-webkit-transform: rotate(-0deg);
					transform: rotate(-0deg);
				}
			}
		}
	}
}

.upcoming-courses-wrapper {
	.course-list {
		a {
			&.button {
				margin-bottom: 0;
			}
		}
	}
}

.course-information-wrapper {
	.full-height{
		height: 100%;
	}
	.content-box {
		margin-top: $global-margin;
	}
	p {
		margin-bottom: 0;
	}
	&.secondary {
		background-color: $medium-gray;
	}
}

.top-bar {
	.relative {
		position: relative;
	}
	.todo-counter,
	.message-counter {
		.fa-layers-counter {
			display: inline-block;
			position: absolute;
			text-align: center;
			background-color: var(--fa-counter-background-color,#ff253a);
			border-radius: var(--fa-counter-border-radius,1em);
			box-sizing: border-box;
			color: var(--fa-inverse,#fff);
			line-height: var(--fa-counter-line-height,1);
			max-width: var(--fa-counter-max-width,5em);
			min-width: var(--fa-counter-min-width,1.5em);
			overflow: hidden;
			padding: var(--fa-counter-padding,.25em .5em);
			right: var(--fa-right,-10px);
			text-overflow: ellipsis;
			top: var(--fa-top,-10px);
			-webkit-transform: scale(var(--fa-counter-scale,.25));
			transform: scale(var(--fa-counter-scale,.25));
			-webkit-transform-origin: top right;
			transform-origin: top right;
		}
	}
}
.to-do-list {
	.align-self-middle {
		a {
			margin-bottom: 0;
		}
	}
	&.remove-top-margin {
		margin-top: 0;
	}
	&.overdue {
		background-color: scale-color( get-color(alert), $lightness: 75% );
	}
	&.due-soon {
		background-color: scale-color( get-color(warning), $lightness: 75% );
	}
}
