$fdatepicker-ui-primary:  var( --ocfa-primary-colour ) !default;
$fdatepicker-ui-color: var( --ocfa-secondary-colour ) !default;
$fdatepicker-ui-bg: var( --ocfa-colour-background ) !default;
$fdatepicker-ui-disabled: #eee !default;
$fdatepicker-ui-border-color: var( --ocfa-table-header-color ) !default;
$fdatepicker-ui-border-transparency: 0.2 !default;
$fdatepicker-ui-border: 1px solid rgba($fdatepicker-ui-color, $fdatepicker-ui-border-transparency) !default;
$fdatepicker-ui-shadow: 0 5px 10px rgba($fdatepicker-ui-color, $fdatepicker-ui-border-transparency) !default;
$fdatepicker-ui-border-radius: 5px !default;
$fdatepicker-ui-before-color: var( --ocfa-primary-colour ) !default;
$fdatepicker-ui-old-new-color: #999 !default;
$fdatepicker-ui-text-color: #333 !default;
$fdatepicker-inline-width: 220px !default;

@import 'foundation-datepicker';

.datepicker td.active.day, .datepicker td.active.year {
	color: var( --ocfa-tertiary-colour );
}
input.datepicker-input {
	height: rem-calc( 50 )!important;
}
.datepicker-hours, .datepicker-minutes {
	thead {
		th {
			visibility: hidden!important;
		}
	}
}
.trainer-select-messages {
	margin-bottom: 0 !important;
}
.media-users-live tr.inactive {
	background-color: var( --ocfa-secondary-colour )!important;
}
.datatable-wrapper {
	padding: 0!important;
}
.fg-toolbar {
	background: var( --ocfa-table-header-colour );
	.dataTables_filter {
		input {
			width: initial;
			display: inline-block;
			margin-left: 10px;
			height: 3.25em!important;
			margin-bottom: 0;
		}
	}
	.dataTables_length {
		select {
			width: initial;
			margin-bottom: 0;
		}
	}
}

.table-search-count td.checked,
.checked.status-filter-btn {
	background-color: var( --ocfa-primary-colour );
	color: $white;
}
.table-search-count td.unchecked {
	background-color: get-color( 'alert' );
	color: $white;
} 

.delete-pdf-file {
	background-color: white;
	cursor: pointer;
}
.delete-pdf-file.fa-trash-can-undo {
	background-color: red;
	color: white;
}
.prepare-remove p a {
	opacity: 0.3;
}

.session-date-wrapper {
	border: 4px solid var( --ocfa-primary-colour );
	border-radius: 15px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 1em;
}
.status-count-wrapper table > tbody {
	border: none;
}