div.dataTables_wrapper div.dataTables_length label {
	float: right!important;
}
div.dataTables_filter label {
	font-size: 1rem;
	[type=search] {
		font-size: 1rem;
		margin-left: 8px!important; 
	}
}
div.datatable-wrapper {
	overflow: scroll;
}
.dataTable tr.yellow td {
	background-color: yellow !important;
}
.dataTable .min-width-125 {
	min-width: 125px;
}
.dataTable .min-width-150 {
	min-width: 150px;
}
.dataTable .min-width-250 {
	min-width: 250px;
}
.dataTable .min-width-400 {
	min-width: 400px;
}