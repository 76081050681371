
.colour-friendly-palette {
	
	body {
		background: var(--colour-friendly-background);
	}
	.content-wrapper {}
	
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		color: var(--colour-friendly-dark);
	}
	hr {
		border-color: var(--colour-friendly-dark)!important;
	}
	
	//ACCESSIBILITY
	.accessibility-toolbar {
		background-color: var(--colour-friendly-dark);
	}
	
	//TOP BAR
	.top-bar, .top-bar ul {
		background-color: var(--colour-friendly-light);
	}
	//MENU
	.dropdown.menu a {
		border: 1px solid var(--colour-friendly-dark);
		background: var(--colour-friendly-dark);
	}
	.dropdown.menu a:hover {
		color: var(--colour-friendly-light);
	}
	.dropdown .is-dropdown-submenu a {
		color: var(--colour-friendly-light);
		border-bottom: 1px solid var(--colour-friendly-light);
	}
	.dropdown .is-dropdown-submenu a:hover {
		border-bottom: 1px solid var(--colour-friendly-light);
		color: var(--colour-friendly-light);
	}
	
	//OFF-CANVAS
	.off-canvas {
		background: var(--colour-friendly-background);
	}
	
	//FONT AWESOME
	.fa-border {
		background-color: var(--colour-friendly-light);
		color: var(--colour-friendly-dark);
	}
	
	.fa-border:hover, .fa-border.hovered {
		background-color: var(--colour-friendly-dark);
		color: var(--colour-friendly-light);
		border-color: var(--colour-friendly-light);
	}
	
	//FORMS
	.button {
		background-color: var(--colour-friendly-dark)!important;
		color: var(--colour-friendly-light)!important;
	}
	.button.secondary {
		background-color: var(--colour-friendly-light)!important;
		color: var(--colour-friendly-dark)!important;
		border: 1px solid var(--colour-friendly-dark);
	}
	.button.secondary:hover {
		background-color: get-color(secondary)!important;
		color: $white!important;
		border: 1px solid get-color(tertiary);
		box-shadow: $box-shadow-dark;
	}
	.input-row {
		background-color: var(--colour-friendly-dark)!important;
	}
	label {
		color: var(--colour-friendly-background);
	}
	input[type="radio"] + span::before,
	input[type="checkbox"] + span::before {
		outline: solid 5px var(--colour-friendly-background);
	}
	 
	input[type="radio"]:checked + span::before,
	input[type="checkbox"]:checked + span::before {
		outline: solid 5px var(--colour-friendly-dark);
	}
	
	//TRAINER
	.announcement-wrapper, 
	.messages-wrapper, 
	.upcoming-courses-wrapper,
	.course-information-wrapper,
	.to-do-wrapper,
	.today-wrapper {
		background: var(--colour-friendly-dark)!important;
		h3 {
			color: var(--colour-friendly-background);
		}
	}
	
	.coming-up-wrapper {
		background-color: var(--colour-friendly-dark)!important;
		h3 {
			color: var(--colour-friendly-background);
		}
	}
	
	.announcement-wrapper, 
	.messages-wrapper, 
	.coming-up-wrapper, 
	.upcoming-courses-wrapper,
	.course-information-wrapper,
	.today-wrapper {
		.content-box {
			background-color: var(--colour-friendly-light)!important;
		}
	}
	
	.messages-wrapper {
		ul {
			li {
				&.trainer {
					background-color:var(--colour-friendly-dark)!important;
					color: $white;
					&:after {
						color: var(--colour-friendly-dark)!important;
					}
				}
			}
		}
	}
}



