$fa-font-path: "../webfonts";
$fa-border-color: var(--ocfa-secondary-colour);
$fa-border-style: solid;
$fa-border-width: 0.08em;
$fa-border-width-large: 0.1em;

@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/light.scss";
@import "./fontawesome/scss/solid.scss";

.fa-2x {
	font-size: 1.5em;
}

.fa-border {
	background-color: $white;
	color: var(--ocfa-primary-colour);
	cursor: pointer;
}

.fa-border.alert {
	background-color: get-color(alert);
	color: $white;
}

.fa-border:hover, .fa-border.hovered {
	background-color: var(--ocfa-primary-colour);
	color: $white;
	border-color: $white;
	box-shadow: $box-shadow-light;
}

.fa-border.has-tip {
	cursor: pointer;
	font-weight: normal;
	border-bottom-width: $fa-border-width-large;
	border-bottom-style: $fa-border-style;
	border-bottom-color: $fa-border-color;
}