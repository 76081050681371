// variables
// --------------------------

$fa-css-prefix          : fa !default;
$fa-style               : 900 !default;
$fa-style-family        : "Font Awesome 6 Pro" !default;

$fa-display             : inline-block !default;

$fa-fw-width            : fa-divide(20em, 16) !default;
$fa-inverse             : #fff !default;

$fa-border-color        : #eee !default;
$fa-border-padding      : .2em .25em .15em !default;
$fa-border-radius       : .1em !default;
$fa-border-style        : solid !default;
$fa-border-width        : .08em !default;

$fa-size-scale-2xs      : 10 !default;
$fa-size-scale-xs       : 12 !default;
$fa-size-scale-sm       : 14 !default;
$fa-size-scale-base     : 16 !default;
$fa-size-scale-lg       : 20 !default;
$fa-size-scale-xl       : 24 !default;
$fa-size-scale-2xl      : 32 !default;

$fa-sizes: (
  "2xs"                 : $fa-size-scale-2xs,
  "xs"                  : $fa-size-scale-xs,
  "sm"                  : $fa-size-scale-sm,
  "lg"                  : $fa-size-scale-lg,
  "xl"                  : $fa-size-scale-xl,
  "2xl"                 : $fa-size-scale-2xl
) !default;

$fa-li-width            : 2em !default;
$fa-li-margin           : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin         : .3em !default;

$fa-primary-opacity     : 1 !default;
$fa-secondary-opacity   : .4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width         : ($fa-fw-width * 2) !default;
$fa-stack-z-index       : auto !default;

$fa-font-display        : block !default;
$fa-font-path           : "../webfonts" !default;

$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-arrows-rotate: \f021;
$fa-var-refresh: \f021;
$fa-var-sync: \f021;
$fa-var-caret-down: \f0d7;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-comment: \f075;
$fa-var-download: \f019;
$fa-var-font: \f031;
$fa-var-house: \f015;
$fa-var-home: \f015;
$fa-var-home-alt: \f015;
$fa-var-home-lg-alt: \f015;
$fa-var-magnifying-glass-arrow-right: \e521;
$fa-var-megaphone: \f675;
$fa-var-palette: \f53f;
$fa-var-print: \f02f;
$fa-var-screwdriver-wrench: \f7d9;
$fa-var-tools: \f7d9;
$fa-var-spinner-third: \f3f4;
$fa-var-text-size: \f894;
$fa-var-trash-can: \f2ed;
$fa-var-trash-alt: \f2ed;
$fa-var-trash-can-undo: \f896;
$fa-var-trash-can-arrow-turn-left: \f896;
$fa-var-trash-undo-alt: \f896;
$fa-var-universal-access: \f29a;
$fa-var-user: \f007;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;


$fa-icons: (
  "angles-left": $fa-var-angles-left,
  "angle-double-left": $fa-var-angle-double-left,
  "arrow-right-from-bracket": $fa-var-arrow-right-from-bracket,
  "sign-out": $fa-var-sign-out,
  "arrows-rotate": $fa-var-arrows-rotate,
  "refresh": $fa-var-refresh,
  "sync": $fa-var-sync,
  "caret-down": $fa-var-caret-down,
  "chevron-left": $fa-var-chevron-left,
  "chevron-right": $fa-var-chevron-right,
  "comment": $fa-var-comment,
  "download": $fa-var-download,
  "font": $fa-var-font,
  "house": $fa-var-house,
  "home": $fa-var-home,
  "home-alt": $fa-var-home-alt,
  "home-lg-alt": $fa-var-home-lg-alt,
  "magnifying-glass-arrow-right": $fa-var-magnifying-glass-arrow-right,
  "megaphone": $fa-var-megaphone,
  "palette": $fa-var-palette,
  "print": $fa-var-print,
  "screwdriver-wrench": $fa-var-screwdriver-wrench,
  "tools": $fa-var-tools,
  "spinner-third": $fa-var-spinner-third,
  "text-size": $fa-var-text-size,
  "trash-can": $fa-var-trash-can,
  "trash-alt": $fa-var-trash-alt,
  "trash-can-undo": $fa-var-trash-can-undo,
  "trash-can-arrow-turn-left": $fa-var-trash-can-arrow-turn-left,
  "trash-undo-alt": $fa-var-trash-undo-alt,
  "universal-access": $fa-var-universal-access,
  "user": $fa-var-user,
  "xmark": $fa-var-xmark,
  "close": $fa-var-close,
  "multiply": $fa-var-multiply,
  "remove": $fa-var-remove,
  "times": $fa-var-times,
);

$fa-brand-icons: (
);

$fa-custom-icons: (
);
