
$box-shadow-dark: 0px 0px 5px 1px get-color(secondary);
$box-shadow-light: 0px 0px 5px 1px $light-gray;

a.nomargin {
	margin: 0;
}
.grid-container {
	margin-top: $global-margin;
	margin-bottom: $global-margin;
}
.content-wrapper {}

//TYPOGRAPHY
.alert-text {
	color: var(--ocfa-alert-colour);
}
.highlight-text {
	color: var(--ocfa-highlight-colour);
}
//ACCESSIBILITY
.accessibility-toolbar {
	background-color: var(--ocfa-primary-colour);
}

//TOP BAR
.top-bar {
	border-radius: $global-radius;
	padding: 0.5rem $global-padding !important;
}
.button.secondary.align-middle {
	margin-bottom: 0.5rem;
}

//MENU
#menu-bar {
	min-height: 80px;
}
.sticky {
	z-index: 1!important;
}
.dropdown.menu li {
}
.dropdown.menu a {
	border: 1px solid var(--ocfa-primary-colour);
	border-radius: $global-radius;
	background: var(--ocfa-primary-colour);
	color: $white;
}
.dropdown.menu a:hover {
	background: $white;
	color: var(--ocfa-primary-colour);
	box-shadow: $box-shadow-light;
}
.dropdown .is-dropdown-submenu a {
	background: none;
	border: none;
	color: var(--ocfa-primary-colour);
	border-bottom: 1px solid var(--ocfa-secondary-colour);
	border-radius: 0;
}
.dropdown .is-dropdown-submenu a:hover {
	border-bottom: 1px solid var(--ocfa-primary-colour);
}
//CANVAS
.position-right {
	width: 50vw!important;
	-webkit-transform: translateX(50vw)!important;
	transform: translateX(50vw)!important;
}
.position-right.is-open {
	-webkit-transform: translateX(0px)!important;
	transform: translateX(0px)!important;
}
@include breakpoint(xxlarge up) { 
	.position-right {
		width: 25vw!important;
		-webkit-transform: translateX(25vw)!important;
		transform: translateX(25vw)!important;
	}
}
//SUB HEADER
.sub-header {
	margin-top: 0.5rem!important;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	a {
		margin-bottom: 0!important;
	}
}

//CONTENT SECTION
.content-section,
.top-margin {
	margin-top: $global-margin;
}

//FOOTER
footer {
	margin-top: 2rem;
}

//FORMS
.submit-button {
	margin-bottom: 0!important;
	&:hover {
		box-shadow: $box-shadow-dark;
	}
}

.button.secondary {
	background-color: var(--ocfa-tertiary-colour)!important;
	color: var(--ocfa-primary-colour)!important;
	border: 1px solid var(--ocfa-primary-colour);
	&:hover {
		background-color: var(--ocfa-secondary-colour)!important;
		color: $white!important;
		border: 1px solid var(--ocfa-tertiary-colour);
		box-shadow: $box-shadow-dark;
	}
}

.input-row {
	background: var(--ocfa-pale-colour);
	border-radius: 15px;
	margin-bottom: $global-margin!important;
	label, input {
		margin-top: $global-margin!important;
	}
	
}
.tabs {
	margin-right: -0.9375rem!important;
	margin-left: -0.9375rem!important;
}
.tabs-content {
	border-radius: 0 0 15px 15px;
	margin-right: -0.9375rem;
	margin-left: -0.9375rem;
}
.accordion-item.archived a,
.tabs-title.archived a {
	opacity: 0.6;
}
.accordion-item.archived .accordion-content,
.tabs-panel.archived {
	background-color: $light-gray;
}
input,
select,
a.submit-button {
	height: rem-calc( 50 )!important;
	border-radius: rem-calc( 15 )!important;
}
a.submit-button:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
select[multiple] {
	min-height: rem-calc( 120 )!important;
}
textarea {
	min-height: rem-calc( 80 )!important;
	border-radius: rem-calc( 15 )!important;
}
input[type="radio"] + span,
input[type="radio"] + span::before,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}
input[type="radio"],
input[type="checkbox"] {
	opacity: 0;
	position: absolute;
	width: 25px;
	height: 25px;
}
input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
	content: "";
	width: 25px;
	height: 25px;
	margin: 0 4px 0 0;
	border: none;
	outline: solid 5px $pale-green;
	line-height: 25px;
	text-align: center;
	 
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	 
	background: #f6f6f6;
	background: -moz-radial-gradient(#f6f6f6, #dfdfdf);
	background: -webkit-radial-gradient(#f6f6f6, #dfdfdf);
	background: -ms-radial-gradient(#f6f6f6, #dfdfdf);
	background: -o-radial-gradient(#f6f6f6, #dfdfdf);
	background: radial-gradient(#f6f6f6, #dfdfdf);
}
 
input[type="radio"]:checked + span::before,
input[type="checkbox"]:checked + span::before {
	color: var(--ocfa-secondary-colour);
	outline: solid 5px var(--ocfa-primary-colour);
}
input[type="radio"]:disabled + span,
input[type="checkbox"]:disabled + span {
	cursor: default;
	 
	-moz-opacity: .4;
	-webkit-opacity: .4;
	opacity: .4;
}
input[type="checkbox"] + span::before {
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}
input[type="radio"]:checked + span::before {
	content: "\2022";
	font-size: 60px;
	margin-top: -1px;
	margin-left: -1px;
}
input[type="checkbox"]:checked + span::before {
	content: "\2714";
	font-size: 23px;
}

.fc-theme-standard {
	td {
		background-color: rgba(0,0,0,0.3);
	}
}
.fc-scrollgrid {
	tbody tr:nth-child(even) {
		border-bottom: 1px solid #f1f1f1 !important;
		background-color: #fefefe !important;
	}
}
.fc-daygrid-day {
	.fc-daygrid-day-number {
		color: $white;
	}
	.fc-daygrid-event {
		color: $white;
		cursor: default;
		.fc-daygrid-event-dot,
		.fc-event-time {
			display: none;
		}
		&.provisional {
			color: $black;
			&.fc-h-event {
				.fc-event-main {
					color: $black;
				}
			}
		}
	}
	.fc-bg-event {
		&.unaccepted,
		&.accepted,
		&.cancelled,
		&.provisional {
			opacity: 1;
			z-index: 3!important;
		}
	}
}
.fc-day-today {
	.fc-daygrid-day-frame {
		border: 1px solid rgb( 255, 220, 40 );
	}
}
.fc-header-toolbar {
	background-color: var(--ocfa-pale-colour);
}
.fc-scrollgrid-sync-table {
	margin-bottom: 0;
}

@media print, screen and (max-width: 39.99875em) {
	.reveal {
		height: auto!important;
		min-height: auto!important;
	}
}